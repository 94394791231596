import ArtsCarousel from "../carousel/artCarousel";
import Link from "next/link";
import Image from "next/image";

const Hero_2 = () => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <figure className="h-[630px] w-full">
            <Image src="/images/gradient.jpg" alt="gradient" layout="fill" />
          </figure>
        </picture>
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <figure className="h-[630px] w-full">
            <Image
              src="/images/gradient_dark.jpg"
              alt="gradient dark"
              layout="fill"
            />
          </figure>
        </picture>

        <div className="container">
          <div className="py-24 text-center">
            <h1 className="text-jacarta-700 font-display mb-6 text-5xl dark:text-white lg:text-6xl xl:text-7xl">
              Be updated on the NFT drops in Sei.
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-lg">
              and Never miss the upcoming NFT drop with SeiCalendar
            </p>
         
          </div>

          <div className="relative">
            {/* <!-- Slider --> */}
            <div className="py-1 text-center">
            <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white lg:text-4xl xl:text-5xl">
              HYPE Projects 🔥
            </h1>
            </div>
            <ArtsCarousel />
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_2;
