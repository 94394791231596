const ArtData = [
	{
		id: 0,
		bigImage: '/images/NFT/seirious.jpg',
		userImage: '/images/NFT/seirious.jpg',
		name: 'Sei-rious',
		title: 'https://twitter.com/Sei_riousNFT',
		
	},
	{
		id: 1,
		bigImage: '/images/NFT/seimachanic.gif',
		userImage: '/images/NFT/seimechanic.jpg',
		name: 'Sei Mechanic',
		title: 'https://twitter.com/SeiMechanic',
		
	},
	{
		id: 2,
		bigImage: '/images/NFT/cappys.jpg',
		userImage: '/images/NFT/cappys.jpg',
		name: 'Cappys',
		title: 'https://twitter.com/CappysNFT',
		
	},
	{
		id: 3,
		bigImage: '/images/NFT/goblin.jpg',
		userImage: '/images/NFT/goblin.jpg',
		name: '6oblin',
		title: 'https://twitter.com/6oblinOfficial',
	},
	{
		id: 4,
		bigImage: '/images/NFT/grape.jpg',
		userImage: '/images/NFT/grape.jpg',
		name: 'GRAPES on SEI',
		title: 'https://twitter.com/GrapesSEI',
	},
];

export default ArtData;
