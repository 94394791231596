import React from "react";
import Hero_2 from "../../components/hero/hero_2";
import {
  Top_collection,
  Auctions_categories,
  NewseLatter,
  Feature_collections,
  Partners,
} from "../../components/component";
import Meta from "../../components/Meta";

const Home_1 = () => {
  return (
    <main>
      <Meta title="SeiCalendar | By Seikates" />
      <Hero_2 />
      <Top_collection />
       {/* auction dropdown 
      <Auctions_categories />
      <Feature_collections />
      <Partners />*/}
    </main>
  );
};

export default Home_1;
